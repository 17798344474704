<template>
	<v-app id="app" :attach="$parent.$el">
		<v-main itemscope itemtype="http://schema.org/LocalBusiness">
			<v-container fluid id="w-container" v-if='!_.isEmpty(application)' class="pa-10">
				<div class="d-none">
					<span itemprop="name">{{ application.unit.name }}</span>
					<span itemprop="address">{{ application.general.address }}</span>
					<div class="d-none" itemprop="aggregateRating"
					     itemtype="https://schema.org/AggregateRating" itemscope>
						<meta itemprop="reviewCount"
						      :content="application.unit.statisticsVerified.liz.comments_24_months_rolling"/>
						<span itemprop="ratingValue"
						      :content="application.unit.statisticsVerified.liz.rate_24_months_rolling">{{
								application.unit.statisticsVerified.liz.rate_24_months_rolling
							}}</span>
					</div>
				</div>
				<v-row>
					<v-col cols="12">
						<div itemprop='publisher' itemscope itemtype="http://schema.org/Place"><h1 itemprop='name'>
							{{ application.unit.name }}</h1></div>
						<div class='d-flex align-center'>
							<v-rating half-increments
							          dense
							          property='ratingValue'
							          readonly
							          empty-icon='mdi-star'
							          active-color='orange'
							          color='#CED3D4'
							          class='d-inline-block mr-2'
							          v-model='application.unit.statisticsVerified.liz.rate_24_months_rolling'>
								<template v-slot:item='props'>
									<v-icon
										:color='getRateColor(props.isFilled, Math.round(application.unit.statisticsVerified.liz.rate_24_months_rolling))'
										small
										style="font-size:25px"
										class='pa-0'
										@click='props.click'>
										{{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
									</v-icon>
								</template>
							</v-rating>

							<b class='d-inline-block' itemprop='ratingValue' id='unit-global-rate'
							   style='font-size:28px'>{{
									application.unit.statisticsVerified.liz.rate_24_months_rolling
								}}</b>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="col" id="w-col" v-if='!_.isEmpty(ratings) && !_.isEmpty(application)'
					       v-for='(rating, i) in ratings'
					       :key='rating.id' itemprop="review" itemscope itemtype="http://schema.org/Review">
						<v-sheet id="w-sheet" class='rounded-lg px-5 pb-5 pt-3' style="background-color: #ededed;">
							<!--							<v-container>-->
							<!--								<v-row>-->
							<v-col cols='12' class='pa-0 d-flex'>
								<v-rating half-increments
								          dense
								          readonly
								          empty-icon='mdi-star'
								          active-color='orange'
								          color='#CED3D4'
								          style='font-size: 19px'
								          class='d-inline-block mr-2 float-left'
								          v-model="rating.rate"
								          itemprop="ratingValue" :content="rating.rate">

									<template v-slot:item="props">
										<v-icon :color="getRateColor(props.isFilled, Math.round(rating.rate))"
										        small
										        class="pa-0"
										        @click="props.click">
											{{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
										</v-icon>
									</template>
								</v-rating>

								<span class='d-flex align-center'>
                      <span v-if="rating.source === 'google' || rating.source === 'google_places'"
                            class='text-capitalize' itemprop="author" itemscope itemtype="https://schema.org/Person">
                        <span>
                              itemprop="name">{{ rating.consumer.identifier }}</span>
                             <span v-if='rating.consumer.influence' class='caption'>
                             {{
		                             i18n.t('local_guide')
	                             }} {{ rating.consumer.influence / 10 }}
                             </span>
                      </span>

                      <span class='caption grey--text text-body-1 text--darken-1 text-capitalize' itemprop="author"
                            itemscope itemtype="https://schema.org/Person"
                            v-else-if="rating.source === 'civiliz' && rating.consumer && rating.consumer.signature !== 'anonymous' && rating.consumer.signature !== 'anonyme'">
                        <span itemprop="name">{{
		                        rating.consumer.signature ? consumerSignature(rating) : consumerIdentifier(rating)
	                        }}</span>
                      </span>
                      <span class='caption grey--text text-body-1 text--darken-1 text-capitalize' itemprop="author"
                            itemscope itemtype="https://schema.org/Person"
                            v-else-if="rating.source === 'liz' && rating.consumer">
                           <span itemprop="name">{{
		                           rating.consumer.signature ? consumerSignature(rating) : consumerIdentifier(rating)
	                           }}</span>
                      </span>
                    </span>
							</v-col>
							<v-col cols='12' class='pa-0 ml-1 pt-0 text-caption'>
								{{ i18n.t('widget.review_on') }} {{ rating.source }} {{
									daysSinceLastDate(rating.created_at)
								}}<span
								itemprop="datePublished" class='d-none'>{{ rating.created_at }}</span>
								<!--							- visité en {{ moment(rating.created_at, 'MM YYYY') }}-->
								<!--							<NuxtTime :locale='locale' data-testid='present' :datetime='rating.created_at' month='long'-->
								<!--							          year='numeric'/>-->
							</v-col>
							<v-col cols='12' class='pa-0 ml-1 pt-2 text-body-1' v-if='rating.contents.length'>
								<span itemprop="reviewBody">{{ rating.contents[0].content }}</span>
							</v-col>
							<v-sheet cols='12' v-if='rating.has_answers' flat v-for='(answer, i) in rating.answers'
							         :key='rating.answers.id'
							         class='answer-sheet pa-3 mb-3 mt-3'
							         rounded='lg'>
								<div class='text-caption mb-2'>
									{{ i18n.t('widget.answer') }}
									{{ daysSinceLastDate(answer.published_at_tz_answer) }}
								</div>
								<div class='text-body-2'>
									{{ answer.content }}
								</div>
							</v-sheet>
							<!--								</v-row>-->
							<!--							</v-container>-->
						</v-sheet>
					</v-col>
				</v-row>
				<v-row v-if="!_.isEmpty(application) && !_.isEmpty(meta)">
					<v-col cols="12" class="text-center">
						<v-btn id="w-feed-btn" :href="'https://reviews.liz.cx/'+application.model+'/'+application.slug"
						       target="_blank">{{ i18n.t('widget.read_reviews') }} {{ meta.pagination.total }} {{ i18n.t('widget.reviews') }}
						</v-btn>
						<div class="text-caption mt-2" id="w-trusted-liz">{{ i18n.t('widget.verified_by_liz') }} <a href="https://liz.cx">Liz.cx</a>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>

import axios from "axios";
import moment from "moment";
import _ from "lodash";
import vuetify from '../../../plugins/vuetify';
import i18n from "../../../config/i18n";

export default {
	name: 'Index',

	mounted() {
	},
	vuetify,

	created() {
		this.getApp();
	},

	components: {},

	props: {
		slug: {
			type: [String],
			default() {
				return ''
			}
		},
		lang: {
			type: [String],
			default() {
				return 'fr'
			}
		},
		version: {
			type: [Number],
			default() {
				return 1
			}
		},
		ratingsNumber: {
			type: [Number],
			default() {
				return 3
			}
		}
	},

	data: () => ({
		ratings: [],
		meta: {},
		application: {},
		moment,
		i18n,

		alignments: [
			'start',
			'center',
			'end',
		],
	}),

	computed: {
		_() {
			return _;
		},
	},

	watch: {},

	methods: {
		async getApp(data) {
			const config = {
				headers: {
					Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOWM3MmE3OTYxZDhhNzNkZjYzMmViYmEwNjk2OTg2NjVjMzNiY2ZiYzY0MGMzZjNmYjk2NGQ4NzYyZjhiNjNkZTIyYmRiNWQ0MmRkZTYyYWQiLCJpYXQiOjE3MDk1NzEwMjMuNjE4NjY2LCJuYmYiOjE3MDk1NzEwMjMuNjE4NjY4LCJleHAiOjE3NDExMDcwMjMuNTg4MTUxLCJzdWIiOiIxIiwic2NvcGVzIjpbImxpei1hcGktcHVibGljIiwicHVibGljLmFwcHMiXX0.D4kXlJ7EE29qIF9JvpJvSfYuM9JYSVAuzNPVqSeT5VjmFb6_Ht4ZEPskStw98RfZiDsQSdSVs0poXm8x2Ps0JOJ9x-vmP4SoH2y_zf81GIJf6B11QwABDZ8isNQ_PW39RClEY3gKENuiJoz9EOEqjB8Gt8Z4xQNaSM4Laoh8g8-F2xIEtaTlwqM-sIjQC-5EBPscX9IvZxTnVgur_lPED2gaJO3MhVG4KBww92iU7U21hhVSXGyTbwnehnXiCuDYxg8wNMHYaZZZsmhhUr54XvWpaShh-frO1QTiS2wb2_SjmQrr58gW7Ma4zPOfBCsTGrujiJnXtBxmdUhZYWRibF-I5jsgFMzHIx2QuxA6QVqVhL9_OxGySdvg6u9knQOPqioFGHw12anZWqFpYdlF3uiL5Sp0Wjl2UZ4oK14Hl6gPBg_FWp7ZbOj_LXen3otOTOA2NCRxgZfa6aQwszrrVkK6UQJE7Sg9s7bnDA8i-1iFr3lRWP2ng2QHP8MTCD17yVOPKHe5NQQLSwTcA2yKPzROf_jd-c4ck2KyhmqUWeV5s-HNCIxE1fFO1myVEKnMgtZoQc03__7vpYoB9RZ7JVZzjXrGfO3RsY3Rw2eYU9SbYD91SaWSTVN4A84K0rB9GNG_XBWmiIC70tlNuJyMCHi6CA8w69ZysDvZlcnHvn4`
				},
				mode: 'no-cors',
			};
			// if(process.env.VUE_APP_ENV === 'local'){
			// 	config.headers.Authorization = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNTZkNDllODhhZWZiY2I4MGQ0YTU4OTYxN2FkMGNjYzM4ODEwMDZjOTRkMzBmMTE5ZDQzNGQ4NTMxZTk4ZWU5ZmI3ZmEwMGQ2ZmE3M2JlNmQiLCJpYXQiOjE3MjM3OTA5OTIuNDU0MDE3LCJuYmYiOjE3MjM3OTA5OTIuNDU0MDE5LCJleHAiOjE3NTUzMjY5OTIuNDQzODY5LCJzdWIiOiIxIiwic2NvcGVzIjpbImxpei1hcGktcHVibGljIiwicHVibGljLnVzZXJzIiwicHVibGljLnVuaXQtZ3JvdXBzIiwicHVibGljLnVuaXRzIiwicHVibGljLnJhdGluZ3MiLCJwdWJsaWMuYXBwcyJdfQ.oLN_9GFXqQBvwTcrVwiNeZGqa8KYzLSwV6XWiufz_yKziUFUumYoXSUcG_FQ8RaRlWgQbTCUPbuz6r0v9JVAIY3xv6If7jHRKjsfrRUfV1gQgIGeFN1TRlh8WmeFajuOT2QuNuS7UVLXvEQZxgFfe8L9T8Pv0QG_SiapsvYxq6FHemJi2PHrKRxfPkC5HuSSBRFTSp_InQc3BQCX8FMkD9fhaQfaOjVFeJgGXsi7nuomZw190nYchoVhdG7QnEpuW22G-Jrvyvs2eLD40Q8kqYrU0nyTtqOB_M6LYn6Iiq5p1VXsdqOW8WiuhkTg7pTHQUEKawcSpafYnMk3T2S-J2dyA4WvszjBxpV9vi069iqqH_rfAZ8t-sz0tpT7YxdTi_9WOE42qaqUIez2FSGtf70mL5zLFJiYPe7k_tMAySmNDxAxNr0tG6a5DLzbuqnGbq1752lxYm-SbMgXV3OWK77n_kDQbiswGYKhUdXXInzm1sLksiTdx6ebPfT73hCoAw8C_LqWBQj1ddprm9TcH_6tbZs66inxtNqyQIVpp3I2CiScVovhq19unpm8-VrMvTYJuG8Jrn8LoGukLsE-1RXffk90oUjA4L37UEo2ATZE8VvWpEfwFxTt36QKKUuveXivc_X-IFZUdV2-OlUaUIjuwWNDcr6eZWDiJd2sxjI'
			// }

			const params = {
				page: 1,
				has_comment: 1,
				interesting: 0,
				with: ['contents', 'consumer', 'answers'],
				in: [],
				limit: this.ratingsNumber <= 10 ? this.ratingsNumber : 3,
				sources: [],
				search: '',
			}

			const selectedFilter = {
				name: 'Les plus récents',
				sortBy: 'posted_at',
				descending: 1
			}

			await axios.post(
				'https://api.liz.cx/public/apps/' + this.slug + '/ratings', {params, selectedFilter},
				config
			).then((result) => {
				i18n.locale = this.lang;
				moment.locale(this.lang)
				this.ratings = result.data.data
				this.meta = result.data.meta
				// console.log(result.data.data)
				// resolve(result);
			}).catch((error) => {
				console.log(error)

				// reject(error)
			})

			await axios.get(
				'https://api.liz.cx/public/apps/' + this.slug,
				config
			).then((result) => {
				// console.log(result.data.data)
				this.application = result.data.data
				if (result.data.data.general.font_override) {
					document.head.insertAdjacentHTML('beforeend', '<link href="' + result.data.data.general.font_override + '" rel="stylesheet"></link>')
				}
				if (result.data.data.general.widget_css_override) {
					document.head.insertAdjacentHTML('beforeend', '<style>' + '@font-face {font-family: "Material Design Icons"; src: url("https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.2.96/fonts/materialdesignicons-webfont.woff") format("woff"); }' + ' ' + result.data.data.general.widget_css_override + '</style>')
				} else {
					document.head.insertAdjacentHTML('beforeend', '<style>' + '@font-face {font-family: "Material Design Icons"; src: url("https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.2.96/fonts/materialdesignicons-webfont.woff") format("woff"); }' + '</style>')
				}
				const host = document.getElementById("liz-widget");
				let style = document.createElement('style')
				style.innerHTML = result.data.data.general.widget_css_override
				host.shadowRoot.appendChild(style)
			}).catch((error) => {
				console.log(error)
			})
		},

		daysSinceLastDate(date) {
			const currentDate = new Date()
			const previousDate = new Date(date)
			const diff = Math.floor(currentDate.getTime() - previousDate.getTime())
			const day = 1000 * 60 * 60 * 24
			const days = Math.floor(diff / day)
			const weeks = Math.floor(days / 7)
			const months = Math.floor(days / 31)
			const years = Math.floor(months / 12)

			let message = i18n.t('widget.since')
			if (weeks === 0 && months === 0 && years === 0) {
				if (days <= 1) {
					message += days + 1 + i18n.t('widget.day')
				} else {
					message += days + 1 + i18n.t('widget.days')
				}
			}
			if (weeks > 0 && months === 0 && years === 0) {
				if (weeks <= 1) {
					message += weeks + i18n.t('widget.week')
				} else {
					message += weeks + i18n.t('widget.weeks')
				}
			}
			if (months > 0 && years === 0) {
				message += months + i18n.t('widget.months')
			}
			if (years > 0) {
				message += years + i18n.t('widget.year')
			}

			return message
		},

		getRateColor(isFilled, rate) {
			let color = 'orange'
			if (isFilled) {
				if (!this.application.configuration.override_rate_stars_color && this.application.configuration.default_rate_stars_color.hexa) {
					color = this.application.configuration.default_rate_stars_color.hexa
				} else {
					color = this.application.configuration['override_rate_stars_color_star_' + rate].hexa
				}
			} else {
				color = 'grey lighten-0'
			}

			return color
		},

		consumerIdentifier(rating) {
			const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
			return regex.test(rating.consumer.identifier) ? '' : rating.consumer.identifier
		},

		consumerSignature(rating) {
			const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
			return regex.test(rating.consumer.signature) ? '' : rating.consumer.signature
		}

	}
};

</script>
<style scoped lang="scss">
@import '../../../../node_modules/vuetify/dist/vuetify.min.css';

@import 'https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/7.2.96/css/materialdesignicons.min.css';
</style>